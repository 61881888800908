import React from "react"
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Spinner,
  Wrap,
  Text,
  useDisclosure,
  PopoverArrow,
} from "@chakra-ui/react"
import { OnChangeDateCallback } from "react-calendar"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"
import dayjs from "dayjs"

import { HiChevronDown } from "react-icons/hi"
import { BiMinus, BiPlus } from "react-icons/bi"

import { Compass, Boat, Passenger, Calendar } from "./Icons"
import destinations from "../lib/static/destinations.json"
import { formDataToSearchParams, searchParamstoQueryString } from "../lib/helpers/search-params"
import { MobileFilters } from "./MobileFilters"
import { AdvancedFilters } from "./AdvancedFilters"
import { BoatCalendar } from "./BoatCalendar"
import { useLocalStorageState } from "src/gatsby/useSessionStorageState"
import { Sort } from "./Sort"

import { BOAT_TYPES } from '../lib/static/boat'

const Filters = ({ isLoading }: { isLoading: boolean }) => {
  const location = useLocation()
  const [, setSearch] = useLocalStorageState("searchParams")

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const searchParams = Object.fromEntries(new URLSearchParams(location.search))
    setSearch(searchParams)
  }, [location.search, setSearch])
  return (
    <>
      <Flex display={{ base: "none", md: "flex" }} justify="space-between" align={{ base: "flex-start", md: "center" }}>
        <Wrap spacing={2} mb={2} align="center">
          <DestinationFilter />
          <PassengersFilter />
          <BoatTypesFilter />
          <CalendarFilter />
          <PriceFilter />
          <AdvancedFilters />
          {isLoading && (
            <Box>
              <Spinner size="sm" ml={8} />
            </Box>
          )}
        </Wrap>
        <Sort />
      </Flex>
      <Flex align="center" display={{ base: "flex", md: "none" }}>
        <MobileFilters />
      </Flex>
    </>
  )
}

export default Filters

const allDestinations = (destinations.reduce((value: any, item: any) => {
  return [...value, item, ...(item.items || []).map((sub: any) => ({ ...sub, pre: true }))]
}, []) as unknown) as { label: string; value: string; key?: string; pre?: boolean }[]

function DestinationFilter() {
  const location = useLocation()

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const searchParams = Object.fromEntries(new URLSearchParams(location.search))

  const setDestination = (destination: string) => {
    const params = formDataToSearchParams({ ...searchParams, destination })
    const qs = searchParamstoQueryString(params)
    navigate(qs !== "" ? `/search?${qs}` : `/search`)
  }

  const selectedDestination = allDestinations.find((d) => d.value === searchParams.destination)

  return (
    <Box>
      <Menu>
        <MenuButton as={Button} fontWeight={400} variant="outline" leftIcon={<Compass boxSize={5} color="primary" />}>
          {selectedDestination ? selectedDestination.label : "All destinations"}
        </MenuButton>
        <MenuList borderRadius={0} outline={0} h={400} overflow="scroll" pb={8} pos="relative">
          {allDestinations.map((destination, i) => (
            <MenuItem
              key={destination.value + i + destination.pre}
              bg={selectedDestination && selectedDestination.value === destination.value ? "gray.100" : "transparent"}
              onClick={() => setDestination(destination.value)}
              pl={destination.pre ? 8 : 4}
              pr={4}
              py={1}
            >
              {destination.label}
            </MenuItem>
          ))}
          <Flex pos="fixed" bottom={0} w="100%" bg="gray.50" align="center" justify="center">
            <Box as={HiChevronDown} boxSize="20px" />
          </Flex>
        </MenuList>
      </Menu>
    </Box>
  )
}

function BoatTypesFilter() {
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const searchParams = Object.fromEntries(new URLSearchParams(location.search))
  const setBoatType = (boatType: string) => {
    const params = formDataToSearchParams({ ...searchParams, boatType })
    const qs = searchParamstoQueryString(params)
    navigate(qs !== "" ? `/search?${qs}` : `/search`)
  }

  const selectedBoatType = BOAT_TYPES.find((t) => t.value === searchParams.boatType)
  return (
    <Box>
      <Menu>
        <MenuButton as={Button} fontWeight={400} variant="outline" leftIcon={<Boat boxSize={5} color="primary" />}>
          {selectedBoatType?.label || "All Boat Types"}
        </MenuButton>
        <MenuList borderRadius={0} outline={0}>
          {BOAT_TYPES.map((type, i) => (
            <MenuItem
              key={type.value + i}
              bg={selectedBoatType?.value === type.value ? "gray.100" : "transparent"}
              onClick={() => setBoatType(type.value)}
              py={2}
            >
              {type.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  )
}
function PassengersFilter() {
  const popoverProps = useDisclosure()
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const searchParams = Object.fromEntries(new URLSearchParams(location.search))
  const passengers = searchParams?.passengers ? parseInt(searchParams.passengers, 10) : 0
  const [count, setCount] = React.useState(passengers)

  const onSetGuests = (e: any) => {
    e.preventDefault()
    const params = formDataToSearchParams({ ...searchParams, passengers: count })
    const qs = searchParamstoQueryString(params)
    popoverProps.onClose()
    navigate(qs !== "" ? `/search?${qs}` : `/search`)
  }

  const increment = () => {
    if (count === 20) return
    setCount(count + 1)
  }
  const decrement = () => {
    if (count === 0) return
    setCount(count - 1)
  }

  const clear = () => {
    const params = formDataToSearchParams({ ...searchParams, passengers: undefined })
    const qs = searchParamstoQueryString(params)
    popoverProps.onClose()
    navigate(qs !== "" ? `/search?${qs}` : `/search`)
  }

  return (
    <Box>
      <Popover placement="bottom-start" {...popoverProps}>
        <PopoverTrigger>
          <Button
            onClick={popoverProps.onOpen}
            fontWeight={400}
            variant="outline"
            leftIcon={<Passenger boxSize={5} color="primary" />}
          >
            Passengers{passengers ? `: ${passengers}` : ""}
          </Button>
        </PopoverTrigger>

        <PopoverContent borderRadius={0}>
          <PopoverArrow />
          <form onSubmit={onSetGuests}>
            <PopoverBody>
              <Flex align="center" justify="space-between" p={4}>
                <Text>Guests</Text>
                <HStack spacing={6}>
                  <IconButton
                    aria-label="decrement"
                    isDisabled={count === 0}
                    as={BiMinus}
                    onClick={decrement}
                    size="xs"
                    borderRadius="full"
                    variant="outline"
                  />
                  <Text color={count === 0 ? "muted" : "black"}>{count}</Text>
                  <IconButton
                    aria-label="increment"
                    as={BiPlus}
                    onClick={increment}
                    size="xs"
                    borderRadius="full"
                    variant="outline"
                  />
                </HStack>
              </Flex>
            </PopoverBody>
            <PopoverFooter>
              <Flex align="center" justify="space-between">
                <Button variant="clear" type="button" onClick={clear}>
                  Clear selection
                </Button>
                <Button type="submit">Apply</Button>
              </Flex>
            </PopoverFooter>
          </form>
        </PopoverContent>
      </Popover>
    </Box>
  )
}

function CalendarFilter() {
  const checkinPopoverProps = useDisclosure()
  const checkoutPopoverProps = useDisclosure()
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const searchParams = Object.fromEntries(new URLSearchParams(location.search))
  const initialCheckin = (searchParams?.checkin && dayjs(searchParams.checkin).format("YYYY-MM-DD")) || null
  const initialCheckout = (searchParams?.checkout && dayjs(searchParams.checkout).format("YYYY-MM-DD")) || null

  const clearCheckinSelection = () => {
    const params = formDataToSearchParams({ ...searchParams, checkin: undefined })
    const qs = searchParamstoQueryString(params)
    checkinPopoverProps.onClose()
    navigate(qs !== "" ? `/search?${qs}` : `/search`)
  }

  const clearCheckoutSelection = () => {
    const params = formDataToSearchParams({ ...searchParams, checkout: undefined })
    const qs = searchParamstoQueryString(params)
    checkoutPopoverProps.onClose()
    navigate(qs !== "" ? `/search?${qs}` : `/search`)
  }
  const onChangeCheckin: OnChangeDateCallback = (val) => {
    let checkin
    if (Array.isArray(val)) {
      checkin = dayjs(val[0]).format("YYYY-MM-DD")
    } else {
      checkin = dayjs(val).format("YYYY-MM-DD")
    }
    const data: any = { ...searchParams, checkin }

    if (!initialCheckout || dayjs(checkin).isAfter(dayjs(initialCheckout))) {
      data.checkout = dayjs(checkin).add(1, "w").format("YYYY-MM-DD")
    }
    const params = formDataToSearchParams(data)
    const qs = searchParamstoQueryString(params)
    checkinPopoverProps.onClose()
    navigate(qs !== "" ? `/search?${qs}` : `/search`)
  }
  const onChangeCheckout: OnChangeDateCallback = (val) => {
    let checkout
    if (Array.isArray(val)) {
      checkout = dayjs(val[0]).format("YYYY-MM-DD")
    } else {
      checkout = dayjs(val).format("YYYY-MM-DD")
    }
    const params = formDataToSearchParams({ ...searchParams, checkout })
    const qs = searchParamstoQueryString(params)
    checkoutPopoverProps.onClose()
    navigate(qs !== "" ? `/search?${qs}` : `/search`)
  }

  return (
    <Box>
      <Popover {...checkinPopoverProps} isLazy>
        <PopoverTrigger>
          <Button
            onClick={checkinPopoverProps.onOpen}
            fontWeight={400}
            variant="outline"
            leftIcon={<Calendar boxSize={5} color="primary" />}
            bg={checkinPopoverProps.isOpen ? "gray.50" : undefined}
          >
            {!initialCheckin ? "Check-in" : dayjs(initialCheckin).format("MMM, D")}
          </Button>
        </PopoverTrigger>

        <PopoverContent w="min-content" bg="white" borderRadius={0}>
          <PopoverArrow />
          <PopoverBody pt={4}>
            <BoatCalendar
              showFixedNumberOfWeeks={true}
              onChange={onChangeCheckin}
              selectedDays={initialCheckout ? [new Date(initialCheckout)] : undefined}
              defaultActiveStartDate={initialCheckin ? new Date(initialCheckin) : undefined}
              value={initialCheckin ? [new Date(initialCheckin)] : null}
            />
          </PopoverBody>
          <PopoverFooter>
            <Flex align="center" justify="space-between">
              <Button variant="clear" onClick={clearCheckinSelection}>
                Clear
              </Button>
            </Flex>
          </PopoverFooter>
          <PopoverFooter bg="primary" color="white" fontSize="sm">
            Yacht charters typically start on Saturdays. In order to give you the best selection, book from Saturday to
            Saturday.
          </PopoverFooter>
        </PopoverContent>
      </Popover>
      <Popover {...checkoutPopoverProps} isLazy>
        <PopoverTrigger>
          <Button
            onClick={checkoutPopoverProps.onOpen}
            fontWeight={400}
            variant="outline"
            borderLeft={0}
            bg={checkoutPopoverProps.isOpen ? "gray.50" : undefined}
          >
            {!initialCheckout ? "Check-out" : dayjs(initialCheckout).format("MMM, D")}
          </Button>
        </PopoverTrigger>

        <PopoverContent w="min-content" bg="white" borderRadius={0}>
          <PopoverArrow />
          <PopoverBody pt={4}>
            <BoatCalendar
              showFixedNumberOfWeeks={true}
              onChange={onChangeCheckout}
              tileDisabled={({ date }) => dayjs(date).isBefore(dayjs(initialCheckin))}
              selectedDays={initialCheckin ? [new Date(initialCheckin)] : undefined}
              defaultActiveStartDate={
                initialCheckout ? new Date(initialCheckout) : initialCheckin ? new Date(initialCheckin) : new Date()
              }
              value={initialCheckout ? [new Date(initialCheckout)] : null}
            />
          </PopoverBody>
          <PopoverFooter>
            <Flex align="center" justify="space-between">
              <Button variant="clear" onClick={clearCheckoutSelection}>
                Clear
              </Button>
            </Flex>
          </PopoverFooter>
          <PopoverFooter bg="primary" color="white" fontSize="sm">
            Yacht charters typically end on Saturdays. In order to give you the best selection, book from Saturday to
            Saturday.
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </Box>
  )
}

export function PriceFilter() {
  const popoverProps = useDisclosure()
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const searchParams = Object.fromEntries(new URLSearchParams(location.search))
  const initialMin = searchParams?.minPrice || ""
  const initialMax = searchParams?.maxPrice || ""

  const [min, setMin] = React.useState(initialMin)
  const [max, setMax] = React.useState(initialMax)

  const setPrice = (e: any) => {
    e.preventDefault()
    const params = formDataToSearchParams({ ...searchParams, minPrice: min || undefined, maxPrice: max || undefined })
    const qs = searchParamstoQueryString(params)
    popoverProps.onClose()
    navigate(qs !== "" ? `/search?${qs}` : `/search`)
  }

  const clearSelection = () => {
    setMin("")
    setMax("")
    const params = formDataToSearchParams({ ...searchParams, minPrice: undefined, maxPrice: undefined })
    const qs = searchParamstoQueryString(params)
    popoverProps.onClose()
    navigate(qs !== "" ? `/search?${qs}` : `/search`)
  }

  const updateMin = (m: string) => {
    try {
      if (m.includes("-")) return
      if (parseInt(m, 10) <= 0) return
      setMin(m)
    } catch (error) {
      //
    }
  }

  const updateMax = (m: string) => {
    try {
      if (m.includes("-")) return
      if (parseInt(m, 10) <= 0) return
      setMax(m)
    } catch (error) {
      //
    }
  }
  return (
    <Box>
      <Popover {...popoverProps} placement="bottom-start">
        <PopoverTrigger>
          <Button onClick={popoverProps.onOpen} fontWeight={400} variant="outline">
            {!initialMin && !initialMax ? (
              "Price"
            ) : (
              <HStack>
                <Text>€{initialMin || 0}</Text>
                <Text>-</Text>
                <Text>€{initialMax || ""}</Text>
              </HStack>
            )}
          </Button>
        </PopoverTrigger>

        <PopoverContent borderRadius={0}>
          <PopoverArrow />
          <form onSubmit={setPrice}>
            <PopoverBody p={6}>
              <HStack align="center" justify="space-between">
                <InputGroup>
                  <InputLeftElement pointerEvents="none" children="€" />
                  <Input
                    borderRadius={0}
                    type="number"
                    placeholder="Minimum"
                    min={1}
                    value={min}
                    onChange={(e) => updateMin(e.target.value)}
                  />
                </InputGroup>

                <InputGroup>
                  <InputLeftElement pointerEvents="none" children="€" />
                  <Input
                    borderRadius={0}
                    type="number"
                    min={1}
                    placeholder="Maximum"
                    value={max}
                    onChange={(e) => updateMax(e.target.value)}
                  />
                </InputGroup>
              </HStack>
            </PopoverBody>
            <PopoverFooter>
              <Flex align="center" justify="space-between">
                <Button type="button" variant="clear" onClick={clearSelection}>
                  Clear
                </Button>
                <Button ml={4} type="submit">
                  Apply
                </Button>
              </Flex>
            </PopoverFooter>
          </form>
        </PopoverContent>
      </Popover>
    </Box>
  )
}
