import React from "react"
import { Box, Link, Text } from "@chakra-ui/react"

export const TrustPilot = () => {
  return (
    <Link
      href="https://uk.trustpilot.com/review/highsails.com"
      target="_blank"
      rel="noreferrer"
      alignItems="center"
      display={{ base: "none", md: "flex" }}
      sx={{
        transform: ["scale(0.47)", "scale(1)"],
        "&:hover": {
          filter: "brightness(110%)",
          transition: "filter .1s ease-out",
        },
      }}
    >
      <Text mt={1} mr={1}>
        Rated excellent on
      </Text>
      <Box
        backgroundRepeat="no-repeat"
        backgroundImage="url(https://cdn.trustpilot.net/brand-assets/1.1.0/logo-black.svg)"
        w="100px"
        h="24px"
      />
    </Link>
  )
}
