import React from "react"
import { navigate } from "gatsby"
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  Checkbox,
  useDisclosure,
  VStack,
} from "@chakra-ui/react"
import { BiMinus, BiPlus } from "react-icons/bi"

import dayjs from "dayjs"
import { useLocation } from "@reach/router"

import destinations from "../lib/static/destinations.json"

import { formDataToSearchParams, searchParamstoQueryString } from "../lib/helpers/search-params"
import { BoatCalendar } from "./BoatCalendar"
import { OnChangeDateCallback } from "react-calendar"
import { Calendar } from "./Icons"
import { humanize } from "src/lib/helpers/text"
import { Sort } from "./Sort"

const allDestinations = (destinations.reduce((dest: any, item: any) => {
  return [...dest, item, ...(item.items || []).map((sub: any) => ({ ...sub, pre: true }))]
}, []) as unknown) as { label: string; value: string; key?: string; pre?: boolean }[]
const boatTypes = [
  { label: "All Boat Types", value: "all" },
  { label: "Sailboat", value: "sailboat" },
  { label: "Catamaran", value: "catamaran" },
  { label: "Motor Boat", value: "motorboat" },
  { label: "Gulet", value: "gulet" },
]
const findDestination = (d: string) => allDestinations.find((item) => item.label === d)

interface ClearFilters {
  destination: string
  checkin: string | null
  checkout: string | null
  minPrice: string
  maxPrice: string
  boatType: string
  passengers: number
  // Advanced
  cabins: number
  toilets: number
  minLength: string
  maxLength: string
  flexibleTerms: boolean
  [key: string]: number | string | boolean | null
}

const CLEAR_FILTERS: ClearFilters = {
  destination: "",
  checkin: null,
  checkout: null,
  minPrice: "",
  maxPrice: "",
  boatType: "",
  passengers: 0,
  // Advanced
  cabins: 0,
  toilets: 0,
  minLength: "",
  maxLength: "",
  flexibleTerms: false,
}

export function MobileFilters() {
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const searchParams = Object.fromEntries(new URLSearchParams(location.search))

  const drawerProps = useDisclosure()

  React.useEffect(() => {
    // eslint-disable-next-line no-undef
    const button = document.querySelector("[id^=gb-widget]")
    if (button) {
      if (drawerProps.isOpen) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        button.style.display = "none"
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        button.style.display = "block"
      }
    }
  }, [drawerProps.isOpen])

  const initialState = {
    destination: searchParams?.destination
      ? allDestinations.find((d) => d.value === searchParams.destination)!.label
      : "",
    checkin: searchParams?.checkin || null,
    checkout: searchParams?.checkout || null,
    minPrice: searchParams?.minPrice || "",
    maxPrice: searchParams?.maxPrice || "",
    boatType: searchParams?.boatType ? boatTypes.find((t) => t.value === searchParams.boatType)!.label : "",
    passengers: searchParams?.passengers ? parseInt(searchParams.passengers, 10) : 0,
    // Advanced
    cabins: searchParams?.cabins ? parseInt(searchParams.cabins, 10) : 0,
    toilets: searchParams?.toilets ? parseInt(searchParams.toilets, 10) : 0,
    minLength: searchParams?.minLength || "",
    maxLength: searchParams?.maxLength || "",
    flexibleTerms: searchParams?.flexibleTerms ? true : false,
  }
  const [filters, setFilters] = React.useState(initialState)

  const updateFilters = (data: Record<string, any>) => {
    setFilters((oldFiltrs) => ({ ...oldFiltrs, ...data }))
  }
  // React.useEffect(() => {
  //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //   // @ts-ignore
  //   const sp = Object.fromEntries(new URLSearchParams(location.search))
  //   const spCheckin = (sp?.checkin && dayjs(sp.checkin).format("YYYY-MM-DD")) || null
  //   const spCheckout = (sp?.checkout && dayjs(sp.checkout).format("YYYY-MM-DD")) || null
  //   updateFilters({ checkin: spCheckin, checkout: spCheckout })
  // }, [location.search])

  const checkinModalProps = useDisclosure()
  const checkoutModalProps = useDisclosure()

  const onChangeCheckin: OnChangeDateCallback = (val) => {
    let checkin
    if (Array.isArray(val)) {
      checkin = dayjs(val[0]).format("YYYY-MM-DD")
    } else {
      checkin = dayjs(val).format("YYYY-MM-DD")
    }

    let checkout
    if (!searchParams.initialCheckout || dayjs(checkin).isAfter(dayjs(searchParams.initialCheckout))) {
      checkout = dayjs(checkin).add(1, "w").format("YYYY-MM-DD")
    }
    updateFilters({ checkin, checkout })
    checkinModalProps.onClose()
  }
  const onChangeCheckout: OnChangeDateCallback = (val) => {
    let checkout
    if (Array.isArray(val)) {
      checkout = dayjs(val[0]).format("YYYY-MM-DD")
    } else {
      checkout = dayjs(val).format("YYYY-MM-DD")
    }
    updateFilters({ checkout })
    checkoutModalProps.onClose()
  }

  const updateMinPrice = (m: string) => {
    try {
      if (m === "-") return
      if (parseInt(m, 10) <= 0) return
      updateFilters({ minPrice: m })
    } catch (error) {
      //
    }
  }
  const updateMaxPrice = (m: string) => {
    try {
      if (m === "-") return
      if (parseInt(m, 10) <= 0) return
      updateFilters({ maxPrice: m })
    } catch (error) {
      //
    }
  }

  const decrementPassengers = () => {
    if (filters.passengers === 0) return
    updateFilters({ passengers: filters.passengers - 1 })
  }
  const incrementPassengers = () => {
    if (filters.passengers === 24) return
    updateFilters({ passengers: filters.passengers + 1 })
  }

  const decrementCabins = () => {
    if (filters.cabins === 0) return
    updateFilters({ cabins: filters.cabins - 1 })
  }
  const incrementCabins = () => {
    if (filters.cabins === 24) return
    updateFilters({ cabins: filters.cabins + 1 })
  }

  const decrementToilets = () => {
    if (filters.toilets === 0) return
    updateFilters({ toilets: filters.toilets - 1 })
  }
  const incrementToilets = () => {
    if (filters.toilets === 24) return
    updateFilters({ toilets: filters.toilets + 1 })
  }

  const updateMinLength = (m: string) => {
    try {
      if (m === "-") return
      if (parseInt(m, 10) <= 0) return
      updateFilters({ minLength: m })
    } catch (error) {
      //
    }
  }
  const updateMaxLength = (m: string) => {
    try {
      if (m === "-") return
      if (parseInt(m, 10) <= 0) return
      updateFilters({ maxLength: m })
    } catch (error) {
      //
    }
  }

  const handleApply = (e: any) => {
    try {
      e.preventDefault()
      let newCheckout = filters.checkout
      if (!filters.checkout && filters.checkin) {
        newCheckout = dayjs(filters.checkin).day(6).format("YYYY-MM-DD")
      }
      const data = {
        checkin: filters.checkin || undefined,
        checkout: newCheckout || undefined,
        minPrice: filters.minPrice || undefined,
        maxPrice: filters.maxPrice || undefined,
        destination: filters.destination ? findDestination(filters.destination)!.value : undefined,
        boatType: filters.boatType ? boatTypes.find((t) => t.label === filters.boatType)!.value : undefined,
        passengers: filters.passengers || undefined,
        // Advanced
        cabins: filters.cabins || 0,
        toilets: filters.toilets || 0,
        minLength: filters.minLength || "",
        maxLength: filters.maxLength || "",
        flexibleTerms: filters.flexibleTerms || false,
      }
      const params = formDataToSearchParams(data)
      const qs = searchParamstoQueryString(params)
      drawerProps.onClose()
      navigate(qs !== "" ? `/search?${qs}` : `/search`)
    } catch {
      drawerProps.onClose()
      navigate(`/search`)
    }
  }

  const handleFlexibleTerms = (flexibleTerms: boolean) => {
    updateFilters({ flexibleTerms })
  }

  const clearFilters = () => {
    setFilters(CLEAR_FILTERS)
    drawerProps.onClose()
    navigate(`/search`)
  }

  return (
    <>
      <Box w="100%">
        <Flex align="center" justify="space-between" w="100%">
          <Button variant="outline" size="sm" fontSize={{ base: "0.9rem", md: "1rem" }} onClick={drawerProps.onOpen}>
            Edit filters
          </Button>
          <Sort />
        </Flex>
        <HStack
          role="button"
          onClick={drawerProps.onOpen}
          display={{ base: "flex", md: "none" }}
          spacing={2}
          wrap="wrap"
          color="gray.500"
          mt={2}
          fontSize="sm"
          divider={<Box border={0}>·</Box>}
        >
          {searchParams.destinationCountry && <Text>{humanize(searchParams.destinationCountry)}</Text>}
          {(searchParams.checkin || searchParams.checkout) && (
            <Text whiteSpace="nowrap">
              {searchParams.checkin && dayjs(searchParams.checkin).format("D MMM")}
              {searchParams.checkout &&
                `${searchParams.checkin ? " - " : ""}${dayjs(searchParams.checkout).format("D MMM")}`}
            </Text>
          )}
          {(searchParams.minPrice || searchParams.maxPrice) && (
            <Text whiteSpace="nowrap">
              {searchParams.minPrice && `€${searchParams.minPrice}`}
              {searchParams.maxPrice && `${searchParams.minPrice ? " - " : ""}€${searchParams.maxPrice}`}
            </Text>
          )}
          {searchParams.boatType && <Text>{humanize(searchParams.boatType)}</Text>}
          {searchParams.passengers && <Text>{searchParams.passengers} passenger(s)</Text>}
          {searchParams.cabins && <Text>{searchParams.cabins} cabin(s)</Text>}
          {searchParams.toilets && <Text>{searchParams.toilets} toilet(s)</Text>}
        </HStack>
      </Box>

      <Drawer {...drawerProps} size="full" placement="top">
        <DrawerOverlay>
          <DrawerContent bottom={0} height="auto">
            <DrawerCloseButton />
            <DrawerHeader>Filters</DrawerHeader>

            <DrawerBody pb={40} px={0}>
              <form id="mobile-form" onSubmit={handleApply}>
                <Stack spacing={6} px={6}>
                  <Box>
                    <Text fontWeight={600} mb={2} fontSize="1.2rem">
                      Price
                    </Text>
                    <Stack>
                      <InputGroup>
                        <InputLeftElement pointerEvents="none" children="€" />
                        <Input
                          type="number"
                          borderRadius={0}
                          placeholder="Minimum"
                          min={1}
                          value={filters.minPrice || ""}
                          onChange={(e) => updateMinPrice(e.target.value)}
                        />
                      </InputGroup>
                      <InputGroup>
                        <InputLeftElement pointerEvents="none" children="€" />
                        <Input
                          type="number"
                          borderRadius={0}
                          min={1}
                          placeholder="Maximum"
                          value={filters.maxPrice || ""}
                          onChange={(e) => updateMaxPrice(e.target.value)}
                        />
                      </InputGroup>
                    </Stack>
                  </Box>
                  <Box>
                    <Text fontWeight={600} mb={2} fontSize="1.2rem">
                      Dates
                    </Text>
                    <Stack>
                      <Flex>
                        <Button
                          onClick={checkinModalProps.onOpen}
                          fontWeight={400}
                          variant="outline"
                          leftIcon={<Calendar boxSize={5} color="primary" />}
                          bg={checkinModalProps.isOpen ? "gray.50" : undefined}
                        >
                          {!filters.checkin ? "Check-in" : dayjs(filters.checkin).format("MMM, D")}
                        </Button>
                        <Button
                          onClick={checkoutModalProps.onOpen}
                          fontWeight={400}
                          variant="outline"
                          borderLeft={0}
                          bg={checkoutModalProps.isOpen ? "gray.50" : undefined}
                        >
                          {!filters.checkout ? "Check-out" : dayjs(filters.checkout).format("MMM, D")}
                        </Button>
                      </Flex>
                      <Modal {...checkinModalProps} isCentered size="lg">
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>Select check-in</ModalHeader>
                          <Box px={4} py={0} pb={4}>
                            <BoatCalendar
                              showFixedNumberOfWeeks={true}
                              selectedDays={filters.checkout ? [new Date(filters.checkout)] : undefined}
                              onChange={onChangeCheckin}
                              defaultActiveStartDate={filters.checkin ? new Date(filters.checkin) : undefined}
                              value={filters.checkin ? [new Date(filters.checkin)] : null}
                            />
                            <Flex justify="flex-end" pt={4}>
                              <Button
                                mr={2}
                                variant="outline"
                                onClick={() => {
                                  updateFilters({ checkin: undefined })
                                  checkinModalProps.onClose()
                                }}
                              >
                                Clear selection
                              </Button>
                            </Flex>
                          </Box>
                        </ModalContent>
                      </Modal>
                      <Modal {...checkoutModalProps} isCentered size="lg">
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>Select check-out</ModalHeader>
                          <Box px={4} py={0} pb={4}>
                            <BoatCalendar
                              showFixedNumberOfWeeks={true}
                              onChange={onChangeCheckout}
                              selectedDays={filters.checkin ? [new Date(filters.checkin)] : undefined}
                              tileDisabled={({ date }) => dayjs(date).isBefore(dayjs(filters.checkin))}
                              defaultActiveStartDate={
                                filters.checkout
                                  ? new Date(filters.checkout)
                                  : filters.checkin
                                  ? new Date(filters.checkin)
                                  : new Date()
                              }
                              value={filters.checkout ? [new Date(filters.checkout)] : null}
                            />
                            <Flex justify="flex-end" pt={4}>
                              <Button
                                mr={2}
                                variant="outline"
                                onClick={() => {
                                  updateFilters({ checkout: undefined })
                                  checkoutModalProps.onClose()
                                }}
                              >
                                Clear selection
                              </Button>
                            </Flex>
                          </Box>
                        </ModalContent>
                      </Modal>
                    </Stack>
                  </Box>

                  <Box>
                    <Text fontWeight={600} mb={2} fontSize="1.2rem">
                      Guests
                    </Text>
                    <HStack spacing={6} align="center">
                      <IconButton
                        aria-label="decrement passengers"
                        as={BiMinus}
                        isDisabled={filters.passengers === 0}
                        onClick={decrementPassengers}
                        size="sm"
                        borderRadius="full"
                        variant="outline"
                      />
                      <Text fontSize="1.2rem" color={filters.passengers === 0 ? "muted" : "black"}>
                        {filters.passengers}
                      </Text>
                      <IconButton
                        aria-label="increment passengers"
                        as={BiPlus}
                        onClick={incrementPassengers}
                        size="sm"
                        borderRadius="full"
                        variant="outline"
                      />
                    </HStack>
                  </Box>
                  <Box>
                    <Text fontWeight={600} mb={2} fontSize="1.2rem">
                      Boat type
                    </Text>
                    <Select
                      borderRadius={0}
                      value={filters.boatType}
                      onChange={(e) => updateFilters({ boatType: e.target.value })}
                    >
                      {boatTypes.map((type, i) => (
                        <option key={type.value + i} value={type.label}>
                          {type.label}
                        </option>
                      ))}
                    </Select>
                  </Box>
                  <Box>
                    <Text fontWeight={600} mb={2} fontSize="1.2rem">
                      Destination
                    </Text>
                    <Select
                      borderRadius={0}
                      value={filters.destination}
                      onChange={(e) => updateFilters({ destination: e.target.value })}
                    >
                      {allDestinations.map((dest, index) => (
                        <option key={`destinationItem${index}`} value={dest.label}>
                          {!!dest.pre && "\u2003"}
                          {dest.label}
                        </option>
                      ))}
                    </Select>
                  </Box>
                  <Box>
                    <Text fontWeight={600} mb={2} fontSize="1.2rem">
                      Cabins
                    </Text>
                    <HStack spacing={6} align="center">
                      <IconButton
                        aria-label="decrement cabins"
                        isDisabled={filters.cabins === 0}
                        as={BiMinus}
                        onClick={decrementCabins}
                        size="sm"
                        borderRadius="full"
                        variant="outline"
                      />
                      <Text fontSize="1.2rem" color={filters.cabins === 0 ? "muted" : "black"}>
                        {filters.cabins}
                      </Text>
                      <IconButton
                        aria-label="increment cabins"
                        as={BiPlus}
                        onClick={incrementCabins}
                        size="sm"
                        borderRadius="full"
                        variant="outline"
                      />
                    </HStack>
                  </Box>
                  <Box>
                    <Text fontWeight={600} mb={2} fontSize="1.2rem">
                      Toilets
                    </Text>
                    <HStack spacing={6} align="center">
                      <IconButton
                        aria-label="decrement toilets"
                        isDisabled={filters.toilets === 0}
                        as={BiMinus}
                        onClick={decrementToilets}
                        size="sm"
                        borderRadius="full"
                        variant="outline"
                      />
                      <Text fontSize="1.2rem" color={filters.toilets === 0 ? "muted" : "black"}>
                        {filters.toilets}
                      </Text>
                      <IconButton
                        aria-label="increment toilets"
                        as={BiPlus}
                        onClick={incrementToilets}
                        size="sm"
                        borderRadius="full"
                        variant="outline"
                      />
                    </HStack>
                  </Box>
                  <Box>
                    <Text fontWeight={600} mb={2} fontSize="1.2rem">
                      Length (ft)
                    </Text>
                    <Stack>
                      <InputGroup>
                        <Input
                          borderRadius={0}
                          type="number"
                          placeholder="Minimum"
                          min={1}
                          max={999}
                          step=".1"
                          value={filters.minLength}
                          onChange={(e) => updateMinLength(e.target.value)}
                        />
                      </InputGroup>

                      <InputGroup>
                        <Input
                          borderRadius={0}
                          type="number"
                          min={1}
                          max={999}
                          step=".1"
                          placeholder="Maximum"
                          value={filters.maxLength}
                          onChange={(e) => updateMaxLength(e.target.value)}
                        />
                      </InputGroup>
                    </Stack>
                  </Box>
                  <Box>
                    <VStack spacing={6} align="baseline">
                      <Text fontWeight={600} fontSize="1.2rem">
                        More Options
                      </Text>
                      <Checkbox
                        isChecked={filters.flexibleTerms}
                        onChange={(e) => handleFlexibleTerms(e.target.checked)}
                      >
                        <Text>Flexible Refund or Rescheduling</Text>
                      </Checkbox>
                    </VStack>
                  </Box>
                </Stack>
              </form>
            </DrawerBody>

            <DrawerFooter borderTop="1px solid" borderColor="gray.100">
              <Flex>
                <Button type="submit" form="mobile-form">
                  Save
                </Button>
                <Button type="button" variant="outline" ml={2} onClick={clearFilters}>
                  Clear
                </Button>
              </Flex>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  )
}
