import React from "react"
import {
  Flex,
  Box,
  Center,
  Heading,
  Stack,
  Input,
  FormControl,
  InputGroup,
  InputLeftElement,
  FormErrorMessage,
  Button,
  Text,
} from "@chakra-ui/react"

import { SearchIcon, Email } from "./Icons"
import { useToast } from "src/lib/hooks/useToast"
import { useLocalStorageState } from "src/gatsby/useSessionStorageState"

interface Props {
  searchParams: { [key: string]: any }
}

export const SearchResultsDeadEnd: React.FC<Props> = ({ searchParams }) => {
  const toast = useToast()

  const [emailFromSession, setEmailFromSession] = useLocalStorageState("email")
  const [email, setEmail] = React.useState(emailFromSession || "")
  const [fullName, setFullName] = React.useState("")

  const onSubmit = async (e: any) => {
    e.preventDefault()
    try {
      const dcq = ((window as unknown) as { _dcq: any })._dcq
      if (dcq) {
        dcq.push(["identify", { email, full_name: fullName }])
        dcq.push(["track", "Dead End", { email, search_params: searchParams }])
      }
      setEmail("")
      setEmailFromSession(email)
      setFullName("")
      toast({
        status: "success",
        description: "Thank you! One of our experts will be in touch with you shortly",
      })
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <Flex w="100%" justify="center" mt={12}>
      <Box maxW="550px" mb={8}>
        <Box textAlign="center">
          <Flex justify="center" mb={8}>
            <Center bg="gray.100" rounded="lg" p={6}>
              <SearchIcon boxSize={6} color="primary" />
            </Center>
          </Flex>
          <Heading size="md" as="h4" variant="h4" fontWeight="bold" mb={2}>
            We're sorry we can't show you more boats matching your search requirements*
          </Heading>
          <Text mb={2}>
            Please either change your search or enter your email below and we can help you find the perfect boat to meet
            your needs
          </Text>
          <Text fontSize="0.8rem">*Not all boats are shown on our website</Text>
        </Box>
        <Box as="form" onSubmit={onSubmit}>
          <Stack direction={{ base: "column", sm: "row" }} spacing={{ base: 0, sm: 4 }} my={5}>
            <Stack w={{ base: "100%", sm: "50%" }} mb={{ base: 3, sm: 0 }}>
              <Text name="fullName" fontSize={16} fontWeight="bold" lineHeight="25px">
                Full Name
              </Text>
              <Input
                placeholder="Type here"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                borderRadius="none"
              />
            </Stack>
            <Stack w={{ base: "100%", sm: "50%" }}>
              <Text fontSize={16} fontWeight="bold" lineHeight="25px">
                E-Mail
              </Text>
              <FormControl isRequired>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" children={<Email boxSize={5} color="primary" />} />
                  <Input
                    placeholder="Email *"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    borderRadius="none"
                  />
                </InputGroup>
                <FormErrorMessage>This field is required</FormErrorMessage>
              </FormControl>
            </Stack>
          </Stack>
          <Flex justify="center">
            <Button size="lg" type="submit">
              SUBMIT
            </Button>
          </Flex>
        </Box>
      </Box>
    </Flex>
  )
}
