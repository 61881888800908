import React from "react"
import { Box, Button, Center, Flex, Spinner, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import { BiChevronLeft, BiChevronRight } from "react-icons/bi"
import { useLocation } from "@reach/router"
import dayjs from "dayjs"
import { navigate } from "gatsby"
import { useQuery } from "react-query"
import useEmblaCarousel from "embla-carousel-react"
import axios from "axios"
import { formDataToSearchParams, searchParamstoQueryString } from "../lib/helpers/search-params"

export const RecommendedWeeks = () => {
  const location = useLocation()
  const isMobile = useBreakpointValue({ base: true, sm: false })
  const flexSize = useBreakpointValue({ base: "50%", sm: "33.3333%", md: "25%", xl: "20%" })
  const align = useBreakpointValue<"start" | "center" | "end">({
    base: "start",
    sm: "center",
    md: "start",
    xl: "center",
  })
  const [emblaRef, embla] = useEmblaCarousel({
    draggable: isMobile,
    containScroll: "keepSnaps",
    slidesToScroll: 1,
    startIndex: align === "center" ? 6 : 3,
    align: align || "center",
  })
  React.useEffect(() => {
    embla?.scrollTo(align === "center" ? 6 : 3)
    // eslint-disable-next-line
  }, [embla, location.search])
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const searchParams = Object.fromEntries(new URLSearchParams(location.search))
  const initialCheckin = (searchParams?.checkin && dayjs(searchParams.checkin).format("YYYY-MM-DD")) || null
  const isCheckinOnSaturday = !!initialCheckin && dayjs(initialCheckin).day() === 6
  const initialCheckout = (searchParams?.checkout && dayjs(searchParams.checkout).format("YYYY-MM-DD")) || null
  const isCheckoutOnSaturday = !!initialCheckout && dayjs(initialCheckout).day() === 6

  const { data: response, isLoading } = useQuery(
    ["offersSearchDates", location.search],
    () =>
      axios.get<{ key_as_string: string; key: number; doc_count: number }[]>("/api/offers-search-dates", {
        params: { ...searchParams, checkin: undefined, checkout: undefined, selectedDate: initialCheckin },
      }),
    {
      keepPreviousData: true,
      staleTime: 6 * 60 * 60 * 1000,
      enabled: !isCheckinOnSaturday || !isCheckoutOnSaturday,
    }
  )
  const goToWeek = ({ checkin, checkout }: { checkin: string; checkout: string }) => {
    const params = formDataToSearchParams({ ...searchParams, checkin, checkout })
    const qs = searchParamstoQueryString(params)
    navigate(qs !== "" ? `/search?${qs}` : `/search`)
  }
  const scrollPrev = React.useCallback(() => {
    embla?.scrollPrev()
  }, [embla])

  const scrollNext = React.useCallback(() => {
    embla?.scrollNext()
  }, [embla])

  if (isCheckinOnSaturday && isCheckoutOnSaturday && initialCheckin !== initialCheckout) return null

  return (
    <Box w="100%" bg="primaryBg" p={{ base: 3, lg: 4 }}>
      <Text color="primary" fontWeight={600} mb={4} fontSize={{ base: "0.8rem", lg: "1rem" }}>
        Yacht charters typically start on Saturdays. In order to give you the best selection, book from Saturday to
        Saturday.
      </Text>
      <Flex align="center" justify="space-between" pos="relative">
        <Center pos="absolute" left={0} h="100%">
          <Button
            display="flex"
            variant="unstyled"
            bg="primary"
            size="sm"
            h="auto"
            minW="min-content"
            p={{ base: 0, sm: 1 }}
            rounded="full"
            onClick={scrollPrev}
            alignItems="center"
            justifyContent="center"
          >
            <Box color="white" boxSize="24px" as={BiChevronLeft} />
          </Button>
        </Center>
        <Flex py={{ base: 3, sm: 4 }} px={{ base: 4, sm: 10 }} w="100%" flex={1} align="center" justify="space-around">
          {isLoading ? (
            <Center>
              <Spinner />
            </Center>
          ) : !response?.data ? (
            <Text>Oops, something has gone wrong here!</Text>
          ) : (
            <Box ref={emblaRef} overflow="hidden" position="relative" w="100%">
              <Flex position="relative">
                {response.data?.map((agg) => {
                  const checkin = dayjs(agg.key_as_string).format("D MMM")
                  const checkout = dayjs(agg.key_as_string).add(7, "d").format("D MMM")
                  return (
                    <Button
                      key={agg.key}
                      display="flex"
                      m={0}
                      p={0}
                      position="relative"
                      flex={`0 0 ${flexSize}`}
                      variant="unstyled"
                      height="min-content"
                      onClick={() =>
                        goToWeek({
                          checkin: dayjs(agg.key_as_string).format("YYYY-MM-DD"),
                          checkout: dayjs(agg.key_as_string).add(7, "d").format("YYYY-MM-DD"),
                        })
                      }
                    >
                      <VStack fontWeight={700}>
                        <Text color="primary" fontSize={{ base: "0.8rem", lg: "1rem" }}>
                          {checkin} - {checkout}
                        </Text>
                        <Text color="gray.500" fontSize={{ base: "0.7rem", lg: "0.9rem" }}>
                          {agg.doc_count} boats
                        </Text>
                      </VStack>
                    </Button>
                  )
                })}
              </Flex>
            </Box>
          )}
        </Flex>
        <Center h="100%" pos="absolute" right={0}>
          <Button
            onClick={scrollNext}
            display="flex"
            variant="unstyled"
            bg="primary"
            size="sm"
            minW="min-content"
            h="auto"
            p={{ base: 0, sm: 1 }}
            rounded="full"
            alignItems="center"
            justifyContent="center"
          >
            <Box color="white" boxSize="24px" as={BiChevronRight} />
          </Button>
        </Center>
      </Flex>
    </Box>
  )
}
