import React from "react"
import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react"
import { BiChevronDown } from "react-icons/bi"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"

import { searchParamstoQueryString } from "../lib/helpers/search-params"

const sortOptions: { [key: string]: string } = {
  recommended: "Recommended",
  priceLowHigh: "Price - Low to high",
  priceHighLow: "Price - High to low",
  discountHighLow: "Discount - High to low",
}

export const Sort = () => {
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const searchParams = Object.fromEntries(new URLSearchParams(location.search))

  const currentSort = searchParams?.sort

  const handleSetSort = (sort: string) => {
    const qs = searchParamstoQueryString({ ...searchParams, sort })
    navigate(qs !== "" ? `/search?${qs}` : `/search`)
  }
  return (
    <Flex align="center" justify={{ base: "flex", md: "flex-end" }}>
      <Text color="muted" mr={2} whiteSpace="nowrap" fontSize={{ base: "0.8rem", md: "1rem" }}>
        Sort by:
      </Text>
      <Menu>
        <MenuButton
          as={Button}
          fontWeight={400}
          fontSize={{ base: "0.8rem", md: "1rem" }}
          variant="link"
          color="primary"
        >
          <Flex align="center">
            {sortOptions[currentSort] || "Recommended"}
            <Box as={BiChevronDown} color="primary" />
          </Flex>
        </MenuButton>
        <MenuList borderRadius={0}>
          {Object.entries(sortOptions).map(([key, label]) => (
            <MenuItem key={key} onClick={() => handleSetSort(key)}>
              {label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  )
}
