import React from "react"
import { useLocation } from "@reach/router"
import {
  Box,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  InputGroup,
  Input,
  Checkbox,
  Text,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
  PopoverArrow,
} from "@chakra-ui/react"
import { BiMinus, BiPlus } from "react-icons/bi"
import { navigate } from "gatsby"

import { formDataToSearchParams, searchParamstoQueryString } from "../lib/helpers/search-params"

import { trackEvent } from "../domain/track"

interface ClearFilters {
  cabins: number
  toilets: number
  minLength: string
  maxLength: string
  flexibleTerms: boolean
  [key: string]: number | string | boolean
}

const CLEAR_FILTERS: ClearFilters = {
  cabins: 0,
  toilets: 0,
  minLength: "",
  maxLength: "",
  flexibleTerms: false,
}

export const AdvancedFilters = () => {
  const popoverProps = useDisclosure()
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const searchParams = Object.fromEntries(new URLSearchParams(location.search))
  const initialState = {
    cabins: searchParams?.cabins ? parseInt(searchParams.cabins, 10) : 0,
    toilets: searchParams?.toilets ? parseInt(searchParams.toilets, 10) : 0,
    minLength: searchParams?.minLength || "",
    maxLength: searchParams?.maxLength || "",
    flexibleTerms: searchParams?.flexibleTerms ? true : false,
  }
  const [filters, setFilters] = React.useState(initialState)
  const [activeFiltersCount, setActiveFiltersCount] = React.useState(0)

  React.useEffect(() => {
    const filtersCount = Object.entries(filters).reduce((sum, [key, value]) => {
      if (value !== CLEAR_FILTERS[key]) {
        return sum + 1
      }
      return sum
    }, 0)
    setActiveFiltersCount(filtersCount)
  }, [filters])

  const updateFilters = (data: Record<string, any>) => {
    setFilters((oldFilters) => ({ ...oldFilters, ...data }))
  }

  const handleApply = (e: any) => {
    e.preventDefault()

    const data = {
      cabins: filters.cabins || 0,
      toilets: filters.toilets || 0,
      minLength: filters.minLength || "",
      maxLength: filters.maxLength || "",
      flexibleTerms: filters.flexibleTerms || false,
    }
    const params = formDataToSearchParams({ ...searchParams, ...data })
    const qs = searchParamstoQueryString(params)
    popoverProps.onClose()
    navigate(qs !== "" ? `/search?${qs}` : `/search`)
  }

  const decrementCabins = () => {
    if (filters.cabins === 0) return
    updateFilters({ cabins: filters.cabins - 1 })
  }
  const incrementCabins = () => {
    if (filters.cabins === 20) return
    updateFilters({ cabins: filters.cabins + 1 })
  }

  const decrementToilets = () => {
    if (filters.toilets === 0) return
    updateFilters({ toilets: filters.toilets - 1 })
  }
  const incrementToilets = () => {
    if (filters.toilets === 20) return
    updateFilters({ toilets: filters.toilets + 1 })
  }

  const updateMinLength = (val: string) => {
    try {
      if (val.includes("-")) return
      if (parseInt(val, 10) <= 0) return
      updateFilters({ minLength: val })
    } catch (error) {
      //
    }
  }
  const updateMaxLength = (val: string) => {
    try {
      if (val.includes("-")) return
      if (parseInt(val, 10) <= 0) return
      updateFilters({ maxLength: val })
    } catch (error) {
      //
    }
  }

  const handleFlexibleTerms = (flexibleTerms: boolean) => {
    updateFilters({ flexibleTerms })
  }

  const clearFilters = () => {
    setFilters(CLEAR_FILTERS)
    const params = formDataToSearchParams({ ...searchParams, ...CLEAR_FILTERS })
    const qs = searchParamstoQueryString(params)
    popoverProps.onClose()
    navigate(qs !== "" ? `/search?${qs}` : `/search`)
  }

  const handleClick = () => {
    popoverProps.onOpen()
    trackEvent({ category: "Fake", action: "clicked", label: "Advanced Filters" })
  }

  return (
    <Box>
      <Popover {...popoverProps} placement="bottom-start">
        <PopoverTrigger>
          <Button fontWeight={400} variant="outline" onClick={handleClick}>
            More Options
            {!!activeFiltersCount && (
              <Text ml={2} color="primary">
                • {activeFiltersCount}
              </Text>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent borderRadius={0}>
          <PopoverArrow />
          <form onSubmit={handleApply}>
            <PopoverBody>
              <Flex align="center" justify="space-between" p={4}>
                <Text>Cabins</Text>
                <HStack spacing={6}>
                  <IconButton
                    aria-label="decrement cabins"
                    isDisabled={filters.cabins === 0}
                    as={BiMinus}
                    onClick={decrementCabins}
                    size="xs"
                    borderRadius="full"
                    variant="outline"
                  />
                  <Text color={filters.cabins === 0 ? "muted" : "black"}>{filters.cabins}</Text>
                  <IconButton
                    aria-label="increment cabins"
                    as={BiPlus}
                    onClick={incrementCabins}
                    size="xs"
                    borderRadius="full"
                    variant="outline"
                  />
                </HStack>
              </Flex>
              <Flex align="center" justify="space-between" p={4}>
                <Text>Toilets</Text>
                <HStack spacing={6}>
                  <IconButton
                    aria-label="decrement toilets"
                    isDisabled={filters.toilets === 0}
                    as={BiMinus}
                    onClick={decrementToilets}
                    size="xs"
                    borderRadius="full"
                    variant="outline"
                  />
                  <Text color={filters.toilets === 0 ? "muted" : "black"}>{filters.toilets}</Text>
                  <IconButton
                    aria-label="increment toilets"
                    as={BiPlus}
                    onClick={incrementToilets}
                    size="xs"
                    borderRadius="full"
                    variant="outline"
                  />
                </HStack>
              </Flex>
              <Flex align="center" justify="space-between" p={4}>
                <Text>Length (ft)</Text>
                <HStack align="center" justify="space-between">
                  <InputGroup>
                    <Input
                      borderRadius={0}
                      type="number"
                      placeholder="Min"
                      min={1}
                      max={999}
                      step=".1"
                      value={filters.minLength}
                      onChange={(e) => updateMinLength(e.target.value)}
                      maxWidth="65px"
                    />
                  </InputGroup>

                  <InputGroup>
                    <Input
                      borderRadius={0}
                      type="number"
                      min={1}
                      max={999}
                      step=".1"
                      placeholder="Max"
                      value={filters.maxLength}
                      onChange={(e) => updateMaxLength(e.target.value)}
                      maxWidth="65px"
                    />
                  </InputGroup>
                </HStack>
              </Flex>
              <Flex align="center" justify="space-between" p={4}>
                <HStack spacing={6}>
                  <Checkbox isChecked={filters.flexibleTerms} onChange={(e) => handleFlexibleTerms(e.target.checked)}>
                    Flexible Rescheduling or Refund
                  </Checkbox>
                </HStack>
              </Flex>
            </PopoverBody>

            <PopoverFooter>
              <Flex align="center" justify="space-between">
                <Button variant="clear" type="button" onClick={clearFilters}>
                  Clear
                </Button>
                <Button type="submit">Apply</Button>
              </Flex>
            </PopoverFooter>
          </form>
        </PopoverContent>
      </Popover>
    </Box>
  )
}
