import React from "react"
import { PageProps } from "gatsby"
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Center,
  chakra,
  Flex,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react"
import { useInfiniteQuery } from "react-query"
import axios from "axios"
import dayjs from "dayjs"

import Layout from "../components/Layout"
import Section from "../components/Section"
import Filters from "../components/Filters"

import { trackSearch } from "../domain/track"

import { BoatBox } from "../components/BoatBox"
import { TrustPilot } from "../components/TrustPilot"
import { Offer, OffersResponse } from "../lib/types/offer"
import { RecommendedWeeks } from "../components/RecommendedWeeks"
import { RequestOfferBanner } from "../components/RequestOfferBanner"

import { useDripTrack } from "src/lib/hooks/useDripTrack"
import { SearchResultsDeadEnd } from "src/components/SearchResultsDeadEnd"
import { getTop3BoatData } from "src/lib/helpers/drip-data"

const OFFERS_CHUNK_SIZE = 6

const splitIntoChunks = (array: any[] | undefined) => {
  if (!array) return []
  const groups = array
    .map((_, i) => {
      return i % OFFERS_CHUNK_SIZE === 0 ? array.slice(i, i + OFFERS_CHUNK_SIZE) : null
    })
    .filter((e) => {
      return e
    })
  return groups
}

export default function Search({ location }: PageProps) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const searchParams = Object.fromEntries(new URLSearchParams(location.search))

  const fetchOffers = ({ pageParam = 0 }) =>
    axios.get<OffersResponse>("/api/offers-search", { params: { ...searchParams, after: pageParam } })

  useDripTrack("Visit search", {
    checkin: searchParams.checkin || "",
    checkout: searchParams.checkout || "",
    destination: searchParams.destination || "",
    passengers: searchParams.passengers || "",
    cabins: searchParams.cabins || "",
    toilets: searchParams.toilets || "",
    boat_type: searchParams.boatType || "",
    min_price: searchParams.minPrice || "",
    max_price: searchParams.maxPrice || "",
    min_length: searchParams.minLength || "",
    max_length: searchParams.maxLength || "",
    flexible_terms: searchParams.flexibleTerms ?? "",
  })
  const {
    data: response,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery(["search", searchParams], fetchOffers, {
    getNextPageParam: (lastPage) => lastPage.data.after,
    keepPreviousData: true,
    staleTime: 6 * 60 * 60 * 1000,
  })
  const count = response?.pages[0].data.count

  React.useEffect(() => {
    if ((isFetching || status === "loading") && count) {
      trackSearch(searchParams)
    }
  }, [searchParams, count, status, isFetching])

  const offers = response?.pages.map((p) => p.data.offers).flat() as Offer[] | undefined

  useDripTrack("Search Boats", getTop3BoatData(offers, searchParams), { enabled: !!offers && offers.length > 2 })
  const chunkedOffers = splitIntoChunks(offers)

  return (
    <>
      <Layout invertedNav noHero>
        <Box
          w="100%"
          mx="auto"
          zIndex={12}
          bg="white"
          pos="sticky"
          top="-1px"
          pb={2}
          pt={4}
          boxShadow="0px 3px 5px 0px rgba(0,0,0,0.04)"
        >
          <Box w="90%" maxW="1280px" mx="auto">
            <Filters isLoading={!!offers && isFetching} />
          </Box>
        </Box>
        <Section mt={4} pb={4} borderBottomWidth={1} borderColor="secondaryBg">
          {!!searchParams.checkin &&
            !!searchParams.checkout &&
            dayjs(searchParams.checkout).isAfter(dayjs(searchParams.checkin).add(14, "d")) && (
              <Alert mb={4} bgColor="secondaryBg">
                <AlertIcon color="secondary" />
                <AlertDescription fontSize={{ base: "sm", md: "md" }}>
                  We currently don’t have any offers that cover more than 14 days, please choose a check-out date 2
                  weeks after check-in
                </AlertDescription>
              </Alert>
            )}
          <Box mb={4}>
            <RecommendedWeeks />
          </Box>
          <Flex justify="space-between" mt={{ base: 6, xl: 0 }} wrap="wrap">
            {count && count > 0 ? (
              <Text whiteSpace="nowrap" color="muted" mb={[4, 0]}>
                We found{" "}
                <chakra.span sx={{ color: "primary" }}>{count >= 10000 ? "more than 10000" : count}</chakra.span> offers
                for you
              </Text>
            ) : (
              <Box />
            )}
            <TrustPilot />
          </Flex>
        </Section>

        <Section mt={8}>
          {status === "loading" ? (
            <Center>
              <Spinner />
            </Center>
          ) : error || count === 0 || !offers ? (
            <Center textAlign="center" color="muted">
              We're sorry we couldn't find any offers to fit your search preferences.
              <br />
              Please change your search criteria and try again.
            </Center>
          ) : (
            <>
              {chunkedOffers.map((offerChunk, index) => {
                return (
                  <>
                    <SimpleGrid key={`offers-${index}`} columns={{ base: 1, lg: 3 }} spacing={8}>
                      {offerChunk?.map((offer) => (
                        <BoatBox key={offer.id} offer={offer} />
                      ))}
                    </SimpleGrid>

                    {offers?.length > 3 && <RequestOfferBanner />}
                  </>
                )
              })}
              {offers?.length <= 3 && <SearchResultsDeadEnd searchParams={searchParams} />}
            </>
          )}
        </Section>
        {hasNextPage && (
          <Section textAlign="center" my={8}>
            <Button isDisabled={isFetchingNextPage} isLoading={isFetchingNextPage} onClick={() => fetchNextPage()}>
              {isFetchingNextPage ? "Loading more..." : hasNextPage ? "Load More" : null}
            </Button>
          </Section>
        )}
      </Layout>
      {/* <SearchContentWallModal /> */}
    </>
  )
}
